import {
    faInstagram,
    faWeixin,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import xian from '../assets/clilogo/xianhongshu.png';
import blog from '../assets/clilogo/blog.png';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import i18n from '../i18n';

export default function CopDesc() {
    const { t } = useTranslation(); // Initialize the translation function

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            i18n.changeLanguage(
                storedLanguage === 'Korean'
                    ? 'ko'
                    : storedLanguage === 'English'
                    ? 'en'
                    : 'fr'
            );
        }
    }, []);

    return (
        <>
            <div className="cop-desc">
                <div className="cop-info">
                    <p>
                        <strong>{t('cop.strong')}</strong>
                        <br />
                        {t('cop.p1')}
                        <br />
                        {t('cop.p2')}
                        <p>{t('cop.p3')}</p>
                        <br />
                        {t('cop.p4')}
                        <br />
                        {t('cop.p5')}
                    </p>
                </div>
                <div className="cop-sns">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/heartyworks_official/?igsh=c3RxN3BuOGd1ZHNl"
                    >
                        <FontAwesomeIcon
                            className="icon"
                            icon={faInstagram}
                        ></FontAwesomeIcon>
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.youtube.com/@HEARTYWORKS/"
                    >
                        <FontAwesomeIcon
                            style={{ padding: '0 0 0 14px' }}
                            className="icon"
                            icon={faYoutube}
                        ></FontAwesomeIcon>
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.xiaohongshu.com/user/profile/5f4ca3b0000000000101df42?xhsshare=CopyLink&appuid=58df57e26a6a69513dbe7082&apptime=1707108837"
                    >
                        <img
                            style={{
                                width: '50px',
                                height: '50px',
                                padding: '0 0 0 6px',
                            }}
                            className="icon"
                            src={xian}
                            alt="xiaohongshu"
                        />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://blog.naver.com/koreanatyourdoor"
                    >
                        <img
                            style={{ width: '45px', height: '45px' }}
                            className="icon"
                            src={blog}
                            alt="naver blog"
                        />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://mp.weixin.qq.com/s/97ybO2ao1_yBaU5rNCywCg?sessionid=1707108793&subscene=0&scene=126&clicktime=1707108935&enterid=1707108935"
                    >
                        <FontAwesomeIcon
                            style={{ padding: '0 0 0 10px' }}
                            className="icon"
                            icon={faWeixin}
                        ></FontAwesomeIcon>
                    </a>
                </div>
            </div>
        </>
    );
}
