import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/files/asset1_small.png';
import america from '../assets/language/미국.png';
import korea from '../assets/language/한국.jpg';
import france from '../assets/language/프랑스.png';

export default function TopNav({ nowPage, changeFn }) {
    const { t, i18n } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);

    // LocalStorage에서 현재 언어를 불러옴
    const localLanguage = localStorage.getItem('language');
    const [language, setLanguage] = useState(
        localLanguage || 'Korean' // 만약 LocalStorage에 저장된 언어가 없다면 'Korean'을 기본값으로 설정
    );

    const [languageSelectOpen, setLanguageSelectOpen] = useState(false);

    useEffect(() => {
        // 페이지 로드 시, LocalStorage에서 불러온 언어를 설정
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            i18n.changeLanguage(
                storedLanguage === 'Korean'
                    ? 'ko'
                    : storedLanguage === 'English'
                    ? 'en'
                    : 'fr'
            );
        }
    }, [i18n]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleLanguageSelect = () => {
        setLanguageSelectOpen(!languageSelectOpen);
    };

    const handleLanguageChange = (lang) => {
        localStorage.setItem('language', lang); // 선택한 언어를 LocalStorage에 저장
        setLanguage(lang);
        i18n.changeLanguage(
            lang === 'Korean' ? 'ko' : lang === 'English' ? 'en' : 'fr'
        );
        setLanguageSelectOpen(false);
    };

    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [menuOpen]);

    return (
        <div className="top-nav">
            <div className="top-items">
                <img id="home" onClick={changeFn} src={logo} alt="logo" />
                <div
                    className={`nav-bar ${menuOpen ? 'open' : ''}`}
                    onClick={toggleMenu}
                >
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {!menuOpen && (
                    <div className="right-nav">
                        <div
                            onClick={changeFn}
                            id="home"
                            className={nowPage === 'home' ? 'active' : ''}
                        >
                            {t('topnav.home')} {/* 번역된 텍스트 */}
                        </div>
                        <div
                            onClick={changeFn}
                            id="mission"
                            className={nowPage === 'mission' ? 'active' : ''}
                        >
                            {t('topnav.mission')} {/* 번역된 텍스트 */}
                        </div>
                        <div
                            onClick={changeFn}
                            id="lab"
                            className={nowPage === 'lab' ? 'active' : ''}
                        >
                            {t('topnav.lab')} {/* 번역된 텍스트 */}
                        </div>
                    </div>
                )}

                <div className="language-select">
                    <button onClick={toggleLanguageSelect}>
                        {language === 'Korean' && (
                            <img src={korea} alt="Korean" />
                        )}
                        {language === 'English' && (
                            <img src={america} alt="English" />
                        )}
                        {language === 'French' && (
                            <img src={france} alt="French" />
                        )}
                    </button>
                    {languageSelectOpen && (
                        <ul className="language-dropdown">
                            <li onClick={() => handleLanguageChange('Korean')}>
                                <img src={korea} alt="Korean" />{' '}
                                {t('languages.korean')}
                            </li>
                            <li onClick={() => handleLanguageChange('English')}>
                                <img src={america} alt="English" />{' '}
                                {t('languages.english')}
                            </li>
                            <li onClick={() => handleLanguageChange('French')}>
                                <img src={france} alt="French" />{' '}
                                {t('languages.french')}
                            </li>
                        </ul>
                    )}
                </div>
            </div>
            {menuOpen && (
                <div className="menu-content">
                    <div
                        onClick={changeFn}
                        id="home"
                        className={nowPage === 'home' ? 'active' : ''}
                    >
                        {t('topnav.home')} {/* 번역된 텍스트 */}
                    </div>
                    <div
                        onClick={changeFn}
                        id="mission"
                        className={nowPage === 'mission' ? 'active' : ''}
                    >
                        {t('topnav.mission')} {/* 번역된 텍스트 */}
                    </div>
                    <div
                        onClick={changeFn}
                        id="lab"
                        className={nowPage === 'lab' ? 'active' : ''}
                    >
                        {t('topnav.lab')} {/* 번역된 텍스트 */}
                    </div>
                </div>
            )}
        </div>
    );
}
