import CopDesc from '../components/CopDesc';
import TopNav from '../components/TopNav';
import mission from '../assets/files/bn1.png';
import exImg1 from '../assets/files/스크린샷 2024-01-31 오후 12.00.02.png';
import exImg2 from '../assets/files/스크린샷 2024-01-31 오후 12.18.42.png';
import exImg3 from '../assets/files/라이프1.png';
import exImg4 from '../assets/files/스크린샷 2024-02-04 오후 11.48.03.png';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export default function Mission({ nowPage, changeFn }) {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        // 페이지 로드 시, LocalStorage에서 불러온 언어를 설정
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            i18n.changeLanguage(
                storedLanguage === 'Korean'
                    ? 'ko'
                    : storedLanguage === 'English'
                    ? 'en'
                    : 'fr'
            );
        }
    }, [i18n]);

    const images = [exImg1, exImg2, exImg3]; // 이미지 배열

    return (
        <div className="mission-main">
            <TopNav nowPage={nowPage} changeFn={changeFn} />
            <img src={mission} alt="our mission" className="mission-page" />
            <div className="mission-cop-desc">
                <div className="items">
                    <h1>{t('mission.mission-cop-desc.h1')}</h1>
                    <p
                        style={{
                            fontSize: '18px',
                            marginTop: '20px',
                            lineHeight: 1.5,
                        }}
                    >
                        {t('mission.mission-cop-desc.p1')}
                        <br />
                        {t('mission.mission-cop-desc.p2')}
                        <br />
                        {t('mission.mission-cop-desc.p3')}
                        <br />
                        {t('mission.mission-cop-desc.p4')}
                    </p>
                </div>
            </div>
            <div className="features-container">
                <div className="feature-items">
                    {t('mission.features', { returnObjects: true }).map(
                        (feature, index) => (
                            <div key={index} className="feature-item">
                                <img
                                    src={images[index]}
                                    alt={feature.subtitle}
                                    className="feature-image"
                                />
                                <h3>{feature.title}</h3>
                                <h2>{feature.subtitle}</h2>
                                <p>{feature.description}</p>
                            </div>
                        )
                    )}
                </div>
            </div>
            <div className="quote-section">
                <blockquote>
                    {t('mission.quote-section.blockquote')}
                    <br />
                    {t('mission.quote-section.blockquote2')}
                    <br />
                    <span className="author">
                        {t('mission.quote-section.blockquote3')}
                    </span>
                </blockquote>
                <p>
                    {t('mission.quote-section.p1')}
                    <br />
                    {t('mission.quote-section.p2')}
                    <br />
                    {t('mission.quote-section.p3')}
                    <strong>{t('mission.quote-section.strong')}</strong>
                    <br />
                    <br />
                    {t('mission.quote-section.p4')}
                    <br />
                    {t('mission.quote-section.p5')}
                    <br />
                    {t('mission.quote-section.p6')}
                    <br />
                    {t('mission.quote-section.p7')}
                    <br />
                    {t('mission.quote-section.p8')}
                </p>
            </div>
            <div className="inclusion-section">
                <div className="items">
                    <img src={exImg4} alt="inclue" className="img" />
                    <div className="content-container">
                        <p className="main-text">
                            {t('mission.inclusion-section.p1')}
                            <br />
                            {t('mission.inclusion-section.p2')}
                        </p>
                        <div className="box"></div>
                        <blockquote className="author">
                            {t('mission.inclusion-section.blockquote1')}
                            <br />
                            {t('mission.inclusion-section.blockquote2')}
                            <br />
                            {t('mission.inclusion-section.blockquote3')}
                            <br />
                            <br />
                            {t('mission.inclusion-section.blockquote4')}
                            <br />
                            {t('mission.inclusion-section.blockquote5')}
                            <br />
                            <br />
                            {t('mission.inclusion-section.blockquote6')}
                            <br />
                            {t('mission.inclusion-section.blockquote7')}
                        </blockquote>
                    </div>
                </div>
            </div>
            <CopDesc />
        </div>
    );
}
