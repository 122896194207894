import CopDesc from '../components/CopDesc';
import TopNav from '../components/TopNav';
import mainImg from '../assets/files/스케치1.png';
import exImg1 from '../assets/files/승균.jpg';
import exImg2 from '../assets/files/실습 기홍주.PNG (1).png';
import exImg3 from '../assets/files/이노마드 우노.jpeg';
import exImg4 from '../assets/files/도멘청담.png';
import exImg5 from '../assets/files/노인 고립.jpg';
import exImg6 from '../assets/files/continued.png';
import LabItem from '../components/LabItem';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const images = [exImg1, exImg2, exImg3, exImg4, exImg5, exImg6]; // 이미지 배열

export default function Lab({ nowPage, changeFn }) {
    const { t } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            i18n.changeLanguage(
                storedLanguage === 'Korean'
                    ? 'ko'
                    : storedLanguage === 'English'
                    ? 'en'
                    : 'fr'
            );
        }
    }, []);

    return (
        <div className="lab-main">
            <TopNav nowPage={nowPage} changeFn={changeFn} />
            <img className="main-img" src={mainImg} alt="Main Img" />
            <div className="title">
                <h1>{t('lab.title')}</h1>
            </div>
            <div className="lab-container">
                <div className="lab-content">
                    {t('lab.labItem', { returnObjects: true }).map(
                        (item, index) => (
                            <LabItem
                                key={index}
                                imgSrc={images[index]}
                                altText={t(item.altText)}
                                title={t(item.title)}
                                description={t(item.description)}
                                extraInfo={
                                    item.extraInfo ? t(item.extraInfo) : null
                                }
                            />
                        )
                    )}
                </div>
            </div>
            <CopDesc />
        </div>
    );
}
