import React from 'react';
import PropTypes from 'prop-types';

const ExampleItem = ({ imgSrc, altText, description, extraClass, isBold, changeFn }) => {
    const handleClick = (e) => {
        if (imgSrc.includes('KakaoTalk_20230201_161735156')) {
            changeFn(e);
            window.scrollTo(0, 0);
        }
    };

    return (
        <div>
            <div className="image-container">
                <img src={imgSrc} alt={altText} className={`cropped-image ${extraClass || ''}`} />
            </div>
            <p id='lab' style={{
                fontWeight: isBold ? 500 : 'normal',
                cursor: imgSrc.includes('KakaoTalk_20230201_161735156') ? 'pointer' : 'default',
            }}
                onClick={handleClick} dangerouslySetInnerHTML={{ __html: description }}></p>
        </div>
    );
};

ExampleItem.propTypes = {
    imgSrc: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    extraClass: PropTypes.string,
    isBold: PropTypes.bool,
    changeFn: PropTypes.func.isRequired,
};

export default ExampleItem;
