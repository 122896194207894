import React, { useState, useEffect } from 'react';

export default function CliCon({ content, activeIndex }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 430);
    const [translateValue, setTranslateValue] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 430);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) {
            setTranslateValue(-activeIndex * 300);
        } else {
            setTranslateValue(-activeIndex * 700);
        }
    }, [activeIndex, isMobile]);

    return (
        <p style={{ transform: `translateX(${translateValue}px)` }}>
            <span dangerouslySetInnerHTML={{ __html: content }} />
        </p>
    );
}
