import TopNav from '../components/TopNav';
import { useEffect, useRef, useState } from 'react';
import HomeVideo from '../assets/files/100 - kaydweb_24.01.mp4';
import exImg1 from '../assets/files/스크린샷 2024-02-01 오전 7.00.34.png';
import exImg2 from '../assets/files/스크린샷 2024-02-01 오전 7.05.16.png';
import exImg3 from '../assets/files/스크린샷 2024-02-01 오전 7.06.38.png';
import exImg4 from '../assets/files/KakaoTalk_20230201_161735156.jpg';
import logo1 from '../assets/files/a-1.. SK온.png';
import logo2 from '../assets/files/a-2.. sk넥실리스.png';
import logo3 from '../assets/files/a-3. sk아이이티.png';
import logo4 from '../assets/files/a-4.. SK지오센트릭.png';
import logo5 from '../assets/files/a-5.. SK케미칼.png';
import logo6 from '../assets/files/a-6.. 현대LNC.png';
import logo7 from '../assets/files/a-7.. 현대ITE.png';
import logo8 from '../assets/files/a-8.. 두산.png';
import logo9 from '../assets/files/a-9.. 한화투자증권.png';
import logo10 from '../assets/files/a-10.. 녹십자.png';
import logo11 from '../assets/files/a-11. 지씨셀.png';
import logo12 from '../assets/files/a-12. 그랜드하얏트.png';
import logo13 from '../assets/files/a-13. 포시즌스.png';
import logo14 from '../assets/files/a-14. 파르나스.png';
import logo15 from '../assets/files/a-15. 게스.png';
import logo16 from '../assets/files/a-16.. 미래엔.png';
import logo17 from '../assets/files/a-17. 빅텍.png';
import logo18 from '../assets/files/a-18.. KPF.png';
import logo19 from '../assets/files/a-19.. KWE.png';
import logo20 from '../assets/files/a-20.. 조아제약.png';
import logo21 from '../assets/files/a-21.. 서양네트웍스.png';
import logo22 from '../assets/files/a-22.. 코코네M.png';
import logo23 from '../assets/files/a-23.. 테크빌교육.png';
import logo24 from '../assets/files/a-24.. 스피덴트.png';
import logo25 from '../assets/files/a-25.. 스타메드.png';
import logo26 from '../assets/files/a-26.. 딥노이드.png';
import logo27 from '../assets/files/a-27.. 래딕스플러스.png';
import logo28 from '../assets/files/a-28.. 디노마드.png';
import logo29 from '../assets/files/a-29. 경포씨앤씨.png';
import logo30 from '../assets/files/a-30.. 알티미디어.png';
import logo31 from '../assets/files/a-31.. 수산인더스트리.png';
import logo32 from '../assets/files/a-32.. 캠파트너스.png';
import logo33 from '../assets/files/a-33.. 다나특허법인.png';
import logo34 from '../assets/files/a-34.. 신흥글로벌.png';
import logo35 from '../assets/files/a-35.. 남양주시.png';
import logo36 from '../assets/files/a-36.. 서울시교육청.png';
import logo37 from '../assets/files/a-37.. KF.png';
import logo38 from '../assets/files/a-38.. 세종학당.png';
import logo39 from '../assets/files/a-39.. KIST.png';
import CliCon from '../components/CliCon';
import CopDesc from '../components/CopDesc';
import ExampleItem from '../components/ExampleItem';
import { useTranslation } from 'react-i18next';

const contents = [
    "'장애인에게는 단순 반복 직무만 맡겨야 한다'는 편견이 틀렸다는 것을<br />하티웍스에서 증명하고 있다.<br /><br />권현우 PM님, Culture Unit, 에스케이온(주)",
    "'강사님의 수업 진행이나 질적인 측면에서 굉장히 도움이 많이 되는 어학과정입니다.<br /> 교육생분들의 만족도가 높은 편입니다.<br /><br />김효중 담당자님, 인재경영실 인사팀, GC 녹십자",
    "'해외 현지 직원들에게 쉽고 편리하게 어학교육을 제공할 수 있어서<br /> 회사와 직원의 니즈를 모두 충족할 수 있었습니다.<br /><br />김민아 선임님, HR Team, (주)두산 전자",
    "'무한한 가능성과 함께 따뜻함이 공존하는<br />리더와 팀원들이 함께 성장하는 하티웍스를 진심으로 응원합니다.<br /><br />홍경희 대리님, Talent Development Team, 파르나스호텔 주식회사",
];

const logos = [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
    logo12,
    logo13,
    logo14,
    logo15,
    logo16,
    logo17,
    logo18,
    logo19,
    logo20,
    logo21,
    logo22,
    logo23,
    logo24,
    logo25,
    logo26,
    logo27,
    logo28,
    logo29,
    logo30,
    logo31,
    logo32,
    logo33,
    logo34,
    logo35,
    logo36,
    logo37,
    logo38,
    logo39,
];

export default function Home({ nowPage, changeFn }) {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        // 페이지 로드 시, LocalStorage에서 불러온 언어를 설정
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            i18n.changeLanguage(
                storedLanguage === 'Korean'
                    ? 'ko'
                    : storedLanguage === 'English'
                    ? 'en'
                    : 'fr'
            );
        }
    }, [i18n]);

    const exampleItems = [
        {
            imgSrc: exImg1,
            altText: t('home.example_items.korean_online_instructor'),
            description: t('home.example_items.korean_online_instructor'),
        },
        {
            imgSrc: exImg2,
            altText: t('home.example_items.foreign_language_instructor'),
            description: t('home.example_items.foreign_language_instructor'),
            extraClass: 'zoomed',
        },
        {
            imgSrc: exImg3,
            altText: t('home.example_items.wellness_counselor'),
            description: t('home.example_items.wellness_counselor'),
        },
        {
            imgSrc: exImg4,
            altText: 'to be continued...',
            description: t('home.example_items.read_more'),
            isBold: true,
        },
    ];
    const videoRef = useRef();
    const [activeIndex, setActiveIndex] = useState(0);

    const handlePageClick = (index) => {
        setActiveIndex(index);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
        }, 7000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="home-main">
            <TopNav nowPage={nowPage} changeFn={changeFn} />
            <video muted autoPlay loop ref={videoRef} className="home-video">
                <source src={HomeVideo} type="video/mp4" />
            </video>
            <div className="title-1">
                <h1
                    dangerouslySetInnerHTML={{
                        __html: t('home.title.innovation'),
                    }}
                />
            </div>
            <div className="mission-service">
                <div className="our-mission">
                    <div className="left">
                        <h1>
                            Our
                            <br />
                            Mission
                        </h1>
                        <div className="box"></div>
                        <p
                            id="mission"
                            onClick={(e) => {
                                changeFn(e);
                                window.scrollTo(0, 0);
                            }}
                        >
                            Read More
                        </p>
                    </div>
                    <div className="right">
                        <div className="items">
                            <h1 style={{ fontSize: '26px' }}>
                                {t('home.title.company_mission')}
                            </h1>
                            <p style={{ marginTop: '40px', lineHeight: 1.5 }}>
                                {t('home.description.education_part1')}
                                <br />
                                {t('home.description.education_part2')}
                                <br />
                                <br />
                                {t('home.description.recognition')}
                                <br />
                                {t('home.description.recognition_awards')}
                                <br />
                                {t('home.description.recognition_awards2')}
                                <br />
                                <br />
                                {t('home.description.inclusive_society_part1')}
                                <br />
                                {t('home.description.inclusive_society_part2')}
                                <br />
                                {t('home.description.inclusive_society_part3')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="our-service">
                    <div className="items">
                        <h1 style={{ fontSize: '50px' }}>
                            {t('home.title.our_service')}
                        </h1>
                        <p style={{ marginTop: '40px', lineHeight: 1.5 }}>
                            {t('home.service_description.overview_part1')}
                            <br />
                            {t('home.service_description.overview_part2')}
                            <br />
                            {t('home.service_description.overview_part3')}
                        </p>
                    </div>
                </div>
            </div>
            <div className="example-pic">
                <div className="items">
                    {exampleItems.map((item, index) => (
                        <ExampleItem
                            key={index}
                            imgSrc={item.imgSrc}
                            altText={item.altText}
                            description={item.description}
                            extraClass={item.extraClass}
                            isBold={item.isBold}
                            changeFn={changeFn}
                        />
                    ))}
                </div>
            </div>
            <div className="client">
                <div className="items">
                    <h1 style={{ fontSize: '50px' }}>{t('home.clients')}</h1>
                    <div className="content">
                        {t('home.cliContent', { returnObjects: true }).map(
                            (content, index) => (
                                <CliCon
                                    key={index}
                                    content={content}
                                    activeIndex={activeIndex}
                                    index={index}
                                />
                            )
                        )}
                    </div>
                    <div className="page">
                        {Array.from({ length: 4 }).map((_, index) => (
                            <p
                                key={index}
                                onClick={() => handlePageClick(index)}
                                style={{
                                    backgroundColor:
                                        activeIndex === index
                                            ? 'black'
                                            : '#85aff1',
                                }}
                            ></p>
                        ))}
                    </div>
                </div>
            </div>
            <div className="logo-scroller">
                <div className="logo-track">
                    {logos.map((logo, index) => (
                        <img
                            key={index}
                            src={logo}
                            alt={`logo${index + 1}`}
                            className="logo"
                        />
                    ))}
                    {logos.map((logo, index) => (
                        <img
                            key={index + logos.length}
                            src={logo}
                            alt={`logo${index + 1}`}
                            className="logo"
                        />
                    ))}
                </div>
            </div>
            <CopDesc />
        </div>
    );
}
