import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationKO from './locales/ko/translation.json';
import translationFR from './locales/fr/translation.json';

const resources = {
    en: {
        translation: translationEN,
    },
    ko: {
        translation: translationKO,
    },
    fr: {
        translation: translationFR,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'ko', // 기본 언어
    fallbackLng: 'ko', // 언어 설정이 없을 경우 사용할 언어
    interpolation: {
        escapeValue: false, // React가 XSS를 방지하므로, escpae를 하지 않도록 설정
    },
});

export default i18n;
