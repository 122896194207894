import './App.css';
import Home from './pages/home';
import Mission from './pages/mission';
import Lab from './pages/lab';
import { useState, useEffect } from 'react';
import './i18n';

function App() {
    const page = localStorage.getItem('page');
    const [nowPage, setNowPage] = useState(page === null ? 'home' : page);

    function changePage(e) {
        const target = e.target.id;

        if (target === 'home') {
            setNowPage('home');
            localStorage.setItem('page', 'home');
            return;
        }
        if (target === 'mission') {
            setNowPage('mission');
            localStorage.setItem('page', 'mission');
            return;
        }
        if (target === 'lab') {
            setNowPage('lab');
            localStorage.setItem('page', 'lab');
            return;
        }
    }

    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.removeItem('page');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <>
            {nowPage === 'home' ? (
                <Home nowPage={nowPage} changeFn={changePage} />
            ) : nowPage === 'mission' ? (
                <Mission nowPage={nowPage} changeFn={changePage} />
            ) : (
                <Lab nowPage={nowPage} changeFn={changePage} />
            )}
        </>
    );
}

export default App;
